import axios from "@/axios-main";
import axiosOrigin from "axios";

let cancelList;
var cancelToken = axiosOrigin.CancelToken;

const indikator = {
  namespaced: true,
  state: {
    // load data indikator
    status_list: {
      status: false,
      cancel: null,
      code: null,
    },
    status_detail: false,

    // indikator
    list: {},
    detail: {},
  },
  mutations: {
    // load data indikator
    setStatusList: (state, status) => {
      state.status_list = status;
    },
    setStatusDetail: (state, status) => {
      state.status_detail = status;
    },

    // indikator
    setList: (state, data) => {
      state.list = data;
    },
    setDetail: (state, data) => {
      state.detail = data;
    },
  },
  getters: {},
  actions: {

    // async getList({ state, commit, dispatch }, [limit='', page='', keyword='']) {
    async getList({ state, commit, dispatch }, [gatra_id='', ancaman_id='']) {

      if (state.status_list.cancel !== null && state.status_list.status === 'loading')
        await state.status_list.cancel('Operation canceled by the user.');

    //   let params = {
    //     limit: limit,
    //     page: page,
    //     keyword: keyword,
    //   };
    
      await axios.get('pacman/indikator/'+ gatra_id +'/'+ ancaman_id, {
        // params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelList = c;
          commit('setStatusList', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data)
        let data = resp.data.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setList', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusList', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelList;
        }

        commit('setStatusList', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    // getDetail({ commit, dispatch }, id) {

    //   // let params = new FormData();
    //   // params.append('id', id);
    
    //   // return axios.post('bff/features/detail/', params)
    //   return axios.get('bff/features/'+id,)
    //   .then((resp) => {
    //     // console.log(resp.data)
    //     let data = resp.data.data;
    //     commit('setDetail', data);
    //     return 'success';
    //   })
    //   .catch((resp) => {
    //     // console.log(resp)
    //     dispatch('errors/handleApiResponse', (resp.response && resp.response.status ? resp.response.status : null), { root: true });
    //     return 'failed';
    //   });
    // },

    create({ commit, dispatch, rootState }, [gatra_id='', ancaman_id='', label='', keyword='']) {

      // let params = new FormData();
      // params.append('gatra_id', gatra_id);
      // params.append('ancaman_id', ancaman_id);
      // params.append('label', label);
      // params.append('keyword', keyword);

      // return axios.post('pacman/indikator-create', params)
      return axios.post('pacman/indikator-create', {
        gatra_id: gatra_id,
        ancaman_id: ancaman_id,
        label: label,
        keyword: keyword,
      })
      .then((resp) => {

        // if (resp.status === 200 && resp.data.message.toLowerCase() === 'success') {
        if (resp.status === 200) {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', (resp.response && resp.response.status ? resp.response.status : null), { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    update({ commit, dispatch, rootState }, [id, gatra_id='', ancaman_id='', label='', keyword='']) {

      // let params = new FormData();
      // params.append('variable_id', id);
      // params.append('gatra_id', gatra_id);
      // params.append('ancaman_id', ancaman_id);
      // params.append('label', label);
      // params.append('keyword', keyword);
    
      // return axios.put('pacman/indikator-update', params)
      return axios.put('pacman/indikator-update', {
        variable_id: id,
        gatra_id: gatra_id,
        ancaman_id: ancaman_id,
        label: label,
        keyword: keyword,
      })
      .then((resp) => {
        // console.log(resp.data)
        
        // if (resp.status === 200 && resp.data.message.toLowerCase() === 'success') {
        if (resp.status === 200) {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', (resp.response && resp.response.status ? resp.response.status : null), { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },

    delete({ commit, dispatch }, [id, gatra_id='', ancaman_id='']) {
      
      let params = new FormData();
      params.append('variable_id', id);
      params.append('gatra_id', gatra_id);
      params.append('ancaman_id', ancaman_id);

      return axios.delete('pacman/indikator-delete', { data: params })
      .then((resp) => {
        // console.log(resp)
        
        // if (resp.status === 200 && resp.data.message.toLowerCase() === 'success') {
        if (resp.status === 200) {
          return 'success';
        } else {
          return 'failed';
        }
      })
      .catch((resp) => {
        // console.log(resp)
        dispatch('errors/handleApiResponse', (resp.response && resp.response.status ? resp.response.status : null), { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });

    },
  }
};

export default indikator;
