import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

export default new Router({
    mode: 'history',
    // scrollBehavior: function(to) {
    //     if (to.hash) {
    //         return { selector: to.hash };
    //     } else {
    //         return { x: 0, y: 0 };
    //     }
    // },
    routes: [
        // Auth
        {
            path: '/login',
            name: 'LoginPage',
            // component: (resolve) => require(['@/pages/auth/Login.vue'], resolve),
            component: () => import('@/pages/auth/Login.vue'),
            meta: {
                layout: 'blank-layout',
            },
        },
        // graph
        {
            path: '/',
            name: 'GraphPage',
            // component: (resolve) => require(['@/pages/graph/Index.vue'], resolve),
            component: () => import('@/pages/graph/Index.vue'),
            meta: {
                layout: 'graph-layout',
                requiresAuth: true,
            },
        },
        // BDI
        {
            path: '/bdi/search',
            // name: 'BDI_SearchShell',
            // component: (resolve) => require(['@/pages/bdi/Index'], resolve),
            component: () => import('@/pages/bdi/Index'),
            children: [
                {
                    path: '',
                    name: 'BDI_SearchResult',
                    // component: (resolve) => require(['@/pages/bdi/search/Index'], resolve),
                    component: () => import('@/pages/bdi/search/Index'),
                },
            ],
        },
        {
            path: '/bdi',
            name: 'BdiPage',
            // component: (resolve) => require(['@/pages/bdi/Index'], resolve),
            component: () => import('@/pages/bdi/Index'),
            meta: {
                requiresAuth: true,
            },
            children: [
                // {
                //     path: 'search',
                //     name: 'BDI_Search',
                //     component: resolve => require(['@/pages/bdi/search/Index'], resolve),
                // },
                {
                    path: 'organisasi/:keyword?',
                    name: 'BDI_ListOfOrganization',
                    // component: (resolve) => require(['@/pages/bdi/ListOfOrganization'], resolve),
                    component: () => import('@/pages/bdi/ListOfOrganization'),
                },
                {
                    path: 'tokoh/:keyword?',
                    name: 'BDI_ListOfActor',
                    // component: (resolve) => require(['@/pages/bdi/ListOfActor'], resolve),
                    component: () => import('@/pages/bdi/ListOfActor'),
                },
                {
                    path: 'isu',
                    name: 'BDI_ListOfIssues',
                    // component: (resolve) => require(['@/pages/bdi/ListOfIssues'], resolve),
                    component: () => import('@/pages/bdi/ListOfIssues'),
                },
                {
                    path: 'foto',
                    // name: 'BDI_ListOfPhoto',
                    // component: (resolve) => require(['@/pages/bdi/ListOfPhoto'], resolve),
                    component: () => import('@/pages/bdi/ListOfPhoto'),
                    children: [
                        {
                            path: '',
                            name: 'BDI_ListOfPhotoIndex',
                            // component: (resolve) => require(['@/pages/bdi/photo/Index'], resolve),
                            component: () => import('@/pages/bdi/photo/Index'),
                        },
                        {
                            path: 'album',
                            name: 'BDI_ListOfPhotoAlbum',
                            // component: (resolve) => require(['@/pages/bdi/photo/Album'], resolve),
                            component: () => import('@/pages/bdi/photo/Album'),
                        },
                        {
                            path: 'form/:id',
                            name: 'BDI_ListOfPhotoForm',
                            // component: (resolve) => require(['@/pages/bdi/photo/Form'], resolve),
                            component: () => import('@/pages/bdi/photo/Form'),
                        },
                    ],
                },
            ],
        },
        // Organization
        {
            path: '/organisasi/:id',
            name: 'OrganizationPage',
            // component: (resolve) => require(['@/pages/organization/Shell'], resolve),
            component: () => import('@/pages/organization/Shell'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: 'detail',
                    name: 'DetailOfOrganization',
                    // component: (resolve) => require(['@/pages/organization/Index'], resolve),
                    component: () => import('@/pages/organization/Index'),
                    children: [
                        {
                            path: 'profil',
                            name: 'ProfileOfOrganization',
                            // component: (resolve) => require(['@/pages/organization/profile/Index'], resolve),
                            component: () => import('@/pages/organization/profile/Index'),
                        },

                        {
                            path: 'profil/log',
                            name: 'ProfileLogOfOrganization',
                            // component: (resolve) => require(['@/pages/organization/profile/Log'], resolve),
                            component: () => import('@/pages/organization/profile/Log'),
                        },

                        {
                            path: 'profil/log/detail/:data?',
                            name: 'DetailProfileLogOfOrganization',
                            // component: (resolve) => require(['@/pages/organization/profile/Old'], resolve),
                            component: () => import('@/pages/organization/profile/Old'),
                        },

                        {
                            path: 'struktur',
                            name: 'StructureOfOrganization',
                            // component: (resolve) => require(['@/pages/organization/structure/Index'], resolve),
                            component: () => import('@/pages/organization/structure/Index'),
                        },
                        {
                            path: 'motif-agenda',
                            name: 'MotiveOfOrganization',
                            // component: (resolve) => require(['@/pages/organization/motive/Index'], resolve),
                            component: () => import('@/pages/organization/motive/Index'),
                        },
                        {
                            path: 'aktivitas',
                            name: 'ActivitiesOfOrganization',
                            // component: (resolve) => require(['@/pages/organization/activities/Index'], resolve),
                            component: () => import('@/pages/organization/activities/Index'),
                        },
                        {
                            path: 'sikap',
                            name: 'FigureOfOrganization',
                            // component: (resolve) => require(['@/pages/organization/figure/Index'], resolve),
                            component: () => import('@/pages/organization/figure/Index'),
                        },
                        {
                            path: 'prilaku',
                            name: 'BehaviorOfOrganization',
                            // component: (resolve) => require(['@/pages/organization/behavior/Index'], resolve),
                            component: () => import('@/pages/organization/behavior/Index'),
                        },
                        {
                            path: 'export-pdf',
                            name: 'ExportPdfOfOrganization',
                            component: () => import('@/pages/organization/pdf/Index'),
                        },
                        {
                            path: 'berita-terkait',
                            name: 'NewsOfOrganization',
                            component: () => import('@/pages/organization/News/Index'),
                        },
                    ],
                },
                {
                    path: 'form',
                    name: 'FormOfOrganization',
                    // component: (resolve) => require(['@/pages/organization/Form'], resolve),
                    component: () => import('@/pages/organization/Form'),
                    children: [
                        {
                            path: 'profile',
                            name: 'FormOfOrganizationProfile',
                            // component: (resolve) => require(['@/pages/organization/profile/Form'], resolve),
                            component: () => import('@/pages/organization/profile/Form'),
                        },
                        {
                            path: 'related-people',
                            name: 'FormOfOrganizationOrganizer',
                            // component: (resolve) => require(['@/pages/organization/profile/PeopleRelated'], resolve),
                            component: () => import('@/pages/organization/profile/PeopleRelated'),
                        },
                        {
                            path: 'Structure',
                            name: 'FormOfOrganizationStructure',
                            // component: (resolve) => require(['@/pages/organization/structure/Form'], resolve),
                            component: () => import('@/pages/organization/structure/Form'),
                        },
                    ],
                },
            ],
        },
        // Actor
        {
            path: '/tokoh/:id',
            name: 'ActorPage',
            // component: (resolve) => require(['@/pages/actor/Shell'], resolve),
            component: () => import('@/pages/actor/Shell'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: 'detail',
                    name: 'DetailOfActor',
                    // component: (resolve) => require(['@/pages/actor/Index'], resolve),
                    component: () => import('@/pages/actor/Index'),
                    children: [
                        {
                            path: 'profil',
                            name: 'ProfileOfActor',
                            // component: (resolve) => require(['@/pages/actor/profile/Index'], resolve),
                            component: () => import('@/pages/actor/profile/Index'),
                        },
                        {
                            path: 'profil/log',
                            name: 'ProfileLogOfActor',
                            // component: (resolve) => require(['@/pages/actor/profile/Log'], resolve),
                            component: () => import('@/pages/actor/profile/Log'),
                        },
                        {
                            path: 'profil/log/detail/:data?',
                            name: 'DetailProfileLogOfActor',
                            // component: (resolve) => require(['@/pages/actor/profile/Old'], resolve),
                            component: () => import('@/pages/actor/profile/Old'),
                        },

                        {
                            path: 'karakter',
                            name: 'CharacterOfActor',
                            // component: (resolve) => require(['@/pages/actor/character/Index'], resolve),
                            component: () => import('@/pages/actor/character/Index'),
                        },
                        {
                            path: 'ambisi',
                            name: 'AmbitionOfActor',
                            // component: (resolve) => require(['@/pages/actor/ambition/Index'], resolve),
                            component: () => import('@/pages/actor/ambition/Index'),
                        },
                        {
                            path: 'opini',
                            name: 'OpinionOfActor',
                            // component: (resolve) => require(['@/pages/actor/opinion/Index'], resolve),
                            component: () => import('@/pages/actor/opinion/Index'),
                        },
                        {
                            path: 'kekuatan',
                            name: 'StrengthOfActor',
                            // component: (resolve) => require(['@/pages/actor/strength/Index'], resolve),
                            component: () => import('@/pages/actor/strength/Index'),
                        },
                        {
                            path: 'keluarga',
                            name: 'FamilyOfActor',
                            // component: (resolve) => require(['@/pages/actor/family/Index'], resolve),
                            component: () => import('@/pages/actor/family/Index'),
                        },
                        {
                            path: 'aktivitas',
                            name: 'ActivitiesOfActor',
                            // component: (resolve) => require(['@/pages/actor/activities/Index'], resolve),
                            component: () => import('@/pages/actor/activities/Index'),
                        },
                        {
                            path: 'motif-agenda',
                            name: 'MotiveOfActor',
                            // component: (resolve) => require(['@/pages/actor/motive/Index'], resolve),
                            component: () => import('@/pages/actor/motive/Index'),
                        },
                        {
                            path: 'sikap',
                            name: 'FigureOfActor',
                            // component: (resolve) => require(['@/pages/actor/figure/Index'], resolve),
                            component: () => import('@/pages/actor/figure/Index'),
                        },
                        {
                            path: 'perilaku',
                            name: 'BehaviorOfActor',
                            // component: (resolve) => require(['@/pages/actor/behavior/Index'], resolve),
                            component: () => import('@/pages/actor/behavior/Index'),
                        },
                        {
                            path: 'berita-terkait',
                            name: 'NewsOfOfActor',
                            // component: (resolve) => require(['@/pages/organization/behavior/Index'], resolve),
                            component: () => import('@/pages/actor/News/Index'),
                        },
                        {
                            path: 'msisdn',
                            name: 'MsisdnOfActor',
                            // component: (resolve) => require(['@/pages/actor/behavior/Index'], resolve),
                            component: () => import('@/pages/actor/msisdn/Shell'),
                            children: [
                                {
                                    path: 'call-history',
                                    name: 'MsisdnOfActorCallHistory',
                                    component: () => import('@/pages/actor/msisdn/callHistory/Index'),
                                },
                                {
                                    path: 'location',
                                    name: 'MsisdnOfActorLocation',
                                    component: () => import('@/pages/actor/msisdn/location/Index'),
                                },
                                {
                                    path: 'location-history',
                                    name: 'MsisdnOfActorLocationHistory',
                                    component: () => import('@/pages/actor/msisdn/locationHistory/Index'),
                                },
                            ],
                        },
                        {
                            path: 'export-pdf',
                            name: 'ExportPdfOfActor',
                            component: () => import('@/pages/actor/pdf/Index'),
                        },
                    ],
                },
                {
                    path: 'form',
                    name: 'FormOfActor',
                    // component: (resolve) => require(['@/pages/actor/Form'], resolve),
                    component: () => import('@/pages/actor/Form'),
                    children: [
                        {
                            path: 'profile',
                            name: 'ProfileOfActorForm',
                            // component: (resolve) => require(['@/pages/actor/profile/Form'], resolve),
                            component: () => import('@/pages/actor/profile/Form'),
                        },
                        {
                            path: 'bio',
                            name: 'ProfileOfActorFormBio',
                            // component: (resolve) => require(['@/pages/actor/profile/FormBiography'], resolve),
                            component: () => import('@/pages/actor/profile/FormBiography'),
                        },
                        {
                            path: 'experience',
                            name: 'ProfileOfActorFormExperience',
                            // component: (resolve) => require(['@/pages/actor/profile/FormExperience'], resolve),
                            component: () => import('@/pages/actor/profile/FormExperience'),
                        },
                        {
                            path: 'character',
                            name: 'CharacterOfActorForm',
                            // component: (resolve) => require(['@/pages/actor/character/Form'], resolve),
                            component: () => import('@/pages/actor/character/Form'),
                        },
                        {
                            path: 'ambition',
                            name: 'AmbitionOfActorForm',
                            // component: (resolve) => require(['@/pages/actor/ambition/Form'], resolve),
                            component: () => import('@/pages/actor/ambition/Form'),
                        },
                        {
                            path: 'opinion',
                            name: 'opinionOfActorForm',
                            // component: (resolve) => require(['@/pages/actor/opinion/Form'], resolve),
                            component: () => import('@/pages/actor/opinion/Form'),
                        },
                        {
                            path: 'strength',
                            name: 'strengthOfActorForm',
                            // component: (resolve) => require(['@/pages/actor/strength/Form'], resolve),
                            component: () => import('@/pages/actor/strength/Form'),
                        },
                        {
                            path: 'family',
                            name: 'familyOfActorForm',
                            // component: (resolve) => require(['@/pages/actor/family/Form'], resolve),
                            component: () => import('@/pages/actor/family/Form'),
                        },
                    ],
                },
            ],

        },
        // Issue
        {
            // path:'/isu/:id',
            path: '/isu/:id',
            name: 'IssuePage',
            // component: (resolve) => require(['@/pages/issue/Shell'], resolve),
            component: () => import('@/pages/issue/Shell'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: 'detail',
                    name: 'IssueOfIndex',
                    // component: (resolve) => require(['@/pages/issue/Index'], resolve),
                    component: () => import('@/pages/issue/Index'),
                    children: [
                        {
                            path: 'profil',
                            name: 'IssueOfprofile',
                            // component: (resolve) => require(['@/pages/issue/profile/Index'], resolve),
                            component: () => import('@/pages/issue/profile/Index'),
                        },
                        {
                            path: 'aktivitas',
                            name: 'IssueOfActivity',
                            // component: (resolve) => require(['@/pages/issue/activities/Index'], resolve),
                            component: () => import('@/pages/issue/activities/Index'),
                        },
                    ],
                },
                {
                    path: 'form',
                    name: 'FormOfIssue',
                    // component: (resolve) => require(['@/pages/issue/Form'], resolve),
                    component: () => import('@/pages/issue/Form'),
                    children: [
                        {
                            path: 'profil',
                            name: 'IssueOfFormprofile',
                            // component: (resolve) => require(['@/pages/issue/profile/Form'], resolve),
                            component: () => import('@/pages/issue/profile/Form'),
                        },
                    ],
                },
            ],
        },

        // Management
        {
            path: '/manajemen',
            name: 'ManagementPage',
            // component: (resolve) => require(['@/pages/management/Shell'], resolve),
            component: () => import('@/pages/management/Shell'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: 'isu/:id?/:data?',
                    // name: 'IssueOfActor',
                    name: 'IssueOfActor',
                    // component: (resolve) => require(['@/pages/management/Index'], resolve),
                    component: () => import('@/pages/management/Index'),
                },
                {
                    path: 'online-news-issue',
                    name: 'OnlineNewsIssue',
                    // component: (resolve) => require(['@/pages/management/Index'], resolve),
                    component: () => import('@/pages/management/OnlineIssue'),
                },
            ],
        },

        // general Report
        {
            path: '/report',
            // name: 'ReportPage',
            component: () => import('@/pages/general/report/Shell'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    name: 'GeneralReport',
                    component: () => import('@/pages/general/report/Index'),
                },
            ],
        },

        // general Cases
        {
            path: '/cases/:id?',
            // name: 'GeneralCasesShell',
            // component: (resolve) => require(['@/pages/general/cases/Shell.vue'], resolve),
            component: () => import('@/pages/general/cases/Shell.vue'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    name: 'GeneralCasesIndex',
                    // component: (resolve) => require(['@/pages/general/cases/Index.vue'], resolve),
                    component: () => import('@/pages/general/cases/Index.vue'),
                },
                // {
                //     path: 'form',
                //     name: 'GeneralCasesForm',
                //     component: resolve => require(["@/pages/general/cases/Form.vue"], resolve),
                // },
                {
                    path: 'create',
                    name: 'GeneralCasesCreate',
                    // component: (resolve) => require(['@/pages/general/cases/Form.vue'], resolve),
                    component: () => import('@/pages/general/cases/Form.vue'),
                },
                {
                    path: 'edit',
                    name: 'GeneralCasesEdit',
                    // component: (resolve) => require(['@/pages/general/cases/Form.vue'], resolve),
                    component: () => import('@/pages/general/cases/Form.vue'),
                },
            ],
        },

        // general profile
        {
            path: '/profile',
            name: 'GeneralProfilePage',
            // component: (resolve) => require(['@/pages/general/profile/Index.vue'], resolve),
            component: () => import('@/pages/general/profile/Index.vue'),
            meta: {
                requiresAuth: true,
            },
        },

        // general Help
        {
            path: '/help',
            name: 'HelpPage',
            // component: (resolve) => require(['@/pages/general/help/Index.vue'], resolve),
            component: () => import('@/pages/general/help/Index.vue'),
            meta: {
                requiresAuth: true,
            },
        },

        // general notification
        {
            path: '/notification',
            name: 'NotificationPage',
            // component: (resolve) => require(['@/pages/general/notification/Index.vue'], resolve),
            component: () => import('@/pages/general/notification/Index.vue'),
            meta: {
                requiresAuth: true,
            },
        },

        // general features
        {
            path: '/features/:id?',
            // name: 'GeneralFeaturesShell',
            // component: (resolve) => require(['@/pages/general/features/Shell.vue'], resolve),
            component: () => import('@/pages/general/features/Shell.vue'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    name: 'GeneralFeaturesIndex',
                    // component: (resolve) => require(['@/pages/general/features/Index.vue'], resolve),
                    component: () => import('@/pages/general/features/Index.vue'),
                },
                {
                    path: 'create',
                    name: 'GeneralFeaturesCreate',
                    // component: (resolve) => require(['@/pages/general/features/Form.vue'], resolve),
                    component: () => import('@/pages/general/features/Form.vue'),
                },
                {
                    path: 'edit',
                    name: 'GeneralFeaturesEdit',
                    // component: (resolve) => require(['@/pages/general/features/Form.vue'], resolve),
                    component: () => import('@/pages/general/features/Form.vue'),
                },
            ],
        },

        // general sub features
        {
            path: '/sub-features/:id?',
            // name: 'GeneralSubFeaturesShell',
            // component: (resolve) => require(['@/pages/general/subFeatures/Shell.vue'], resolve),
            component: () => import('@/pages/general/subFeatures/Shell.vue'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    name: 'GeneralSubFeaturesIndex',
                    // component: (resolve) => require(['@/pages/general/subFeatures/Index.vue'], resolve),
                    component: () => import('@/pages/general/subFeatures/Index.vue'),
                },
                {
                    path: 'create',
                    name: 'GeneralSubFeaturesCreate',
                    // component: (resolve) => require(['@/pages/general/subFeatures/Form.vue'], resolve),
                    component: () => import('@/pages/general/subFeatures/Form.vue'),
                },
                {
                    path: 'edit',
                    name: 'GeneralSubFeaturesEdit',
                    // component: (resolve) => require(['@/pages/general/subFeatures/Form.vue'], resolve),
                    component: () => import('@/pages/general/subFeatures/Form.vue'),
                },
            ],
        },

        // general ACL
        {
            path: '/acl',
            name: 'AclPage',
            // component: (resolve) => require(['@/pages/general/acl/Index.vue'], resolve),
            component: () => import('@/pages/general/acl/Index.vue'),
        },

        // general Roles
        {
            // path: '/roles/:id?/:data?',
            path: '/roles/:id?',
            // name: 'GeneralRolesShell',
            // component: (resolve) => require(['@/pages/general/roles/Shell.vue'], resolve),
            component: () => import('@/pages/general/roles/Shell.vue'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    name: 'GeneralRolesIndex',
                    // component: (resolve) => require(['@/pages/general/roles/Index.vue'], resolve),
                    component: () => import('@/pages/general/roles/Index.vue'),
                },
                // {
                //     path: 'form',
                //     name: 'GeneralRolesForm',
                //     component: resolve => require(["@/pages/general/roles/Form.vue"], resolve),
                // },
                {
                    path: 'create',
                    name: 'GeneralRolesCreate',
                    // component: (resolve) => require(['@/pages/general/roles/Form.vue'], resolve),
                    component: () => import('@/pages/general/roles/Form.vue'),
                },
                {
                    path: 'edit',
                    name: 'GeneralRolesEdit',
                    // component: (resolve) => require(['@/pages/general/roles/Form.vue'], resolve),
                    component: () => import('@/pages/general/roles/Form.vue'),
                },
            ],
        },

        // general Users
        {
            // path: '/users/:id?/:data?',
            path: '/users/:id?',
            // name: 'GeneralUsersShell',
            // component: (resolve) => require(['@/pages/general/users/Shell.vue'], resolve),
            component: () => import('@/pages/general/users/Shell.vue'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    name: 'GeneralUsersIndex',
                    // component: (resolve) => require(['@/pages/general/users/Index.vue'], resolve),
                    component: () => import('@/pages/general/users/Index.vue'),
                },
                // {
                //     path: 'form',
                //     name: 'GeneralUsersForm',
                //     component: resolve => require(["@/pages/general/users/Form.vue"], resolve),
                // },
                {
                    path: 'create',
                    name: 'GeneralUsersCreate',
                    // component: (resolve) => require(['@/pages/general/users/Form.vue'], resolve),
                    component: () => import('@/pages/general/users/Form.vue'),
                },
                {
                    path: 'edit',
                    name: 'GeneralUsersEdit',
                    // component: (resolve) => require(['@/pages/general/users/Form.vue'], resolve),
                    component: () => import('@/pages/general/users/Form.vue'),
                },
            ],
        },

        // ancaman
        {
            path: '/ancaman',
            // name: 'GeneralAncamanShell',
            component: () => import('@/pages/general/ancaman/Shell.vue'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    name: 'GeneralAncamanIndex',
                    component: () => import('@/pages/general/ancaman/Index.vue'),
                },
                {
                    path: 'create',
                    name: 'GeneralAncamanCreate',
                    component: () => import('@/pages/general/ancaman/Form.vue'),
                },
                {
                    path: 'edit/:data',
                    name: 'GeneralAncamanEdit',
                    component: () => import('@/pages/general/ancaman/Form.vue'),
                }
            ],
        },

        // indikator
        {
            path: '/indikator',
            // name: 'GeneralIndikatorShell',
            component: () => import('@/pages/general/indikator/Shell.vue'),
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    name: 'GeneralIndikatorIndex',
                    component: () => import('@/pages/general/indikator/Index.vue'),
                },
                {
                    path: 'create',
                    name: 'GeneralIndikatorCreate',
                    component: () => import('@/pages/general/indikator/Form.vue'),
                },
                {
                    path: 'edit/:gatra_id/:ancaman_id/:data',
                    name: 'GeneralIndikatorEdit',
                    component: () => import('@/pages/general/indikator/Form.vue'),
                }
            ],
        },

    ],
});
