import {axiosGraphql, axiosNtx} from '@/axios-main';
import axios from '@/axios-main';
import axiosOrigin from 'axios';
import moment from 'moment';

const cancelToken = axiosOrigin.CancelToken;
let cancelListNews;
let cancelRelatedNews;
let cancelRelatedReport;
let cancelSearchReport;
let cancelDetailReport;
var cancelDetailReportFunc;

const news = {
    namespaced: true,
    state: {
        status_list_news: {
            status: 'false',
            cancel: null,
            code: null,
        },
        status_related_news: {
            status: 'false',
            cancel: null,
            code: null,
        },
        status_related_report: {
            status: 'false',
            cancel: null,
            code: null,
        },
        status_detail_report: false,
        status_search_report: {
            status: 'false',
            cancel: null,
            code: null,
        },

        list_news: {},
        related_news: {},
        related_report: {},
        detail_report: {},
        search_report: {},
    },
    mutations: {
        setStatusListNews: (state, status) => {
            state.status_list_news = status;
        },
        setStatusRelatedNews: (state, status) => {
            state.status_related_news = status;
        },
        setStatusRelatedReport: (state, status) => {
            state.status_related_report = status;
        },
        setStatusDetailReport: (state, status) => {
            state.status_detail_report = status;
        },
        setStatusSearchReport: (state, status) => {
            state.status_search_report = status;
        },

        setListNews: (state, data) => {
            state.list_news = data;
        },
        setRelatedNews: (state, data) => {
            state.related_news = data;
        },
        setRelatedReport: (state, data) => {
            state.related_report = data;
        },
        setDetailReport: (state, data) => {
            state.detail_report = data;
        },
        setSearchReport: (state, data) => {
            state.search_report = data;
        },
    },
    getters: {},
    actions: {
        async getListNews({state, commit, dispatch}, [limit=10, page=1, search, startDate='', endDate='']) {
            if (state.status_list_news.cancel !== null && state.status_list_news.status === 'loading') {
                await state.status_list_news.cancel('Operation canceled by the user.');
            }
            await axiosGraphql.post(null, {
                query: `query ($search: SearchParam){ 
                    searchNews (
                        limit: `+ limit +`,
                        page: `+ page +`,
                        search: $search,
                        start_date: "`+ startDate +`",
                        end_date: "`+ endDate +`",
                    ) {
                        doc {
                            title
                            source
                            url
                            urlToImage
                            content
                            description
                            publishedAt
                        }
                        pagination {
                            perPage
                            total
                            totalPage
                            prevPage
                            hasPrev
                            nextPage
                            hasNext
                        }
                        latest_crawl
                        oldest_crawl
                    }
                }`,
                variables: {
                    search: search,
                },
            }, {
                cancelToken: new cancelToken(function executor(c) {
                    cancelListNews = c;
                    commit('setStatusListNews', {
                        status: 'loading',
                        cancel: c,
                        code: null,
                    });
                }),
            })
                .then((resp) => {
                    const data = resp.data.data.searchNews;
                    const result = {
                        status: true,
                        cancel: null,
                        code: resp.response && resp.response.status ? resp.response.status : null,
                    };

                    // console.log(data)
                    commit('setListNews', data);

                    if (Object.keys(data).length===0) {
                        result.status = 'empty';
                    }

                    commit('setStatusListNews', result);
                })
                .catch((resp) => {
                    // console.log(resp)
                    const result = {
                        status: 'failed',
                        cancel: null,
                        code: resp.response && resp.response.status ? resp.response.status : null,
                    };

                    if (axiosOrigin.isCancel(resp)) {
                        result.status = 'loading';
                        result.cancel = cancelListNews;
                    }

                    commit('setStatusListNews', result);
                    if (!axiosOrigin.isCancel(resp)) {
                        dispatch('errors/handleApiResponse', [result.code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], {root: true});
                    }
                });
        },

        async getRelatedNews({state, commit, dispatch}, [limit=10, page=1, name, entityClass, gatra='', dateFrom='', dateTo='']) {
            if (state.status_related_news.cancel !== null && state.status_related_news.status === 'loading') {
                await state.status_related_news.cancel('Operation canceled by the user.');
            }
            const today = moment().format('YYYY-MM-DD');
            dateTo = dateTo != ''? dateTo : today;
            const params = {
                'query': name,
                // 'entity-class': entityClass,
                'entity_class': entityClass,
                'gatra': gatra,
                'date_from': dateFrom,
                'date_to': dateTo,
                'per_page': limit,
                'page': page,
                'source': 'berita',
            };
            // await axiosNtx.get('entity/UNKNOWN/event?query='+name+'&entity-class='+entityClass+'&gatra='+gatra, {
            // await axiosNtx.get('entity/UNKNOWN/event', {
            await axios.get('pacman/fetch-berita-terkait/UNKNOWN', {
                params: params,
                cancelToken: new cancelToken(function executor(c) {
                    cancelRelatedNews = c;
                    commit('setStatusRelatedNews', {
                        status: 'loading',
                        cancel: c,
                        code: null,
                    });
                }),
            })
                .then((resp) => {
                    const data = resp.data;
                    const result = {
                        status: true,
                        cancel: null,
                        code: resp.response && resp.response.status ? resp.response.status : null,
                    };

                    commit('setRelatedNews', data);

                    if (Object.keys(data).length===0) {
                        result.status = 'empty';
                    }

                    commit('setStatusRelatedNews', result);
                })
                .catch((resp) => {
                    // console.log(resp)
                    const result = {
                        status: 'failed',
                        cancel: null,
                        code: resp.response && resp.response.status ? resp.response.status : null,
                    };

                    if (axiosOrigin.isCancel(resp)) {
                        result.status = 'loading';
                        result.cancel = cancelRelatedNews;
                    }

                    commit('setStatusRelatedNews', result);
                    if (!axiosOrigin.isCancel(resp)) {
                        dispatch('errors/handleApiResponse', [result.code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], {root: true});
                    }
                });
        },

        async getRelatedReport({state, commit, dispatch}, [limit=10, page=1, name, entityClass, gatra='', dateFrom='', dateTo='', source='laporan']) {
            if (state.status_related_report.cancel !== null && state.status_related_report.status === 'loading') {
                await state.status_related_report.cancel('Operation canceled by the user.');
            }
            const today = moment().format('YYYY-MM-DD');
            dateTo = dateTo != ''? dateTo : today;
            const params = {
                'query': name,
                // 'entity-class': entityClass,
                'entity_class': entityClass,
                'gatra': gatra,
                'date_from': dateFrom,
                'date_to': dateTo,
                'per_page': limit,
                'page': page,
                'source': source,
            };
            // await axiosNtx.get('entity/UNKNOWN/event?query='+name+'&entity-class='+entityClass+'&gatra='+gatra, {
            // await axiosNtx.get('entity/UNKNOWN/event', {
            await axios.get('pacman/fetch-berita-terkait/UNKNOWN', {
                params: params,
                cancelToken: new cancelToken(function executor(c) {
                    cancelRelatedReport = c;
                    commit('setStatusRelatedReport', {
                        status: 'loading',
                        cancel: c,
                        code: null,
                    });
                }),
            })
                .then((resp) => {
                    const data = resp.data;
                    const result = {
                        status: true,
                        cancel: null,
                        code: resp.response && resp.response.status ? resp.response.status : null,
                    };

                    commit('setRelatedReport', data);

                    if (Object.keys(data).length===0) {
                        result.status = 'empty';
                    }

                    commit('setStatusRelatedReport', result);
                })
                .catch((resp) => {
                    // console.log(resp)
                    const result = {
                        status: 'failed',
                        cancel: null,
                        code: resp.response && resp.response.status ? resp.response.status : null,
                    };

                    if (axiosOrigin.isCancel(resp)) {
                        result.status = 'loading';
                        result.cancel = cancelRelatedReport;
                    }

                    commit('setStatusRelatedReport', result);
                    if (!axiosOrigin.isCancel(resp)) {
                        dispatch('errors/handleApiResponse', [result.code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], {root: true});
                    }
                });
        },

        async getDetailReport({ commit, dispatch, state }, title) {
            // commit('setStatusDetailReport', 'loading');
            if (cancelDetailReportFunc !== null && state.status_detail_report === 'loading') {
                await cancelDetailReportFunc('Operation canceled by the user.');
            }

            return axios.post('kaze/detail-laporan', {
                perihal: title,
                // for testing only
                // perihal: 'PERMASALAHAN MENONJOL WILAYAH PAPUA BARAT PERIODE 9 S.D 11 DESEMBER 2019',
            }, {
                cancelToken: new cancelToken(function executor(c) {
                    cancelDetailReport = c;
                    cancelDetailReportFunc = c;
                    commit('setStatusDetailReport', 'loading');
                }),
            })
                .then((resp) => {
                    // console.log(resp.data)
                    let data = resp.data.data;
                    commit('setStatusDetailReport', true);
                    commit('setDetailReport', data);
                    return 'success';
                })
                .catch((resp) => {
                    // console.log(resp)
                    // commit('setStatusDetailReport', 'error');

                    if (axiosOrigin.isCancel(resp)) {
                        commit('setStatusDetailReport', 'loading');
                        cancelDetailReportFunc = cancelDetailReport;
                    } else {
                        cancelDetailReportFunc = null;
                        commit('setStatusDetailReport', 'error');
                        dispatch('errors/handleApiResponse', [(resp.response && resp.response.status ? resp.response.status : null), (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
                    }
                    return 'failed';
                });
        },

        async getSearchReport({state, commit, dispatch}, [limit=10, page=1, keyword='', dateFrom='', dateTo='']) {
            if (state.status_search_report.cancel !== null && state.status_search_report.status === 'loading') {
                await state.status_search_report.cancel('Operation canceled by the user.');
            }

            const params = {
                'limit': limit,
                'page': page,
                'keyword': keyword,
                'start_date': dateFrom,
                'end_date': dateTo,
            };
            await axios.get('kaze/search-laporan', {
                params: params,
                cancelToken: new cancelToken(function executor(c) {
                    cancelSearchReport = c;
                    commit('setStatusSearchReport', {
                        status: 'loading',
                        cancel: c,
                        code: null,
                    });
                }),
            })
                .then((resp) => {
                    const data = resp.data.data;
                    const result = {
                        status: true,
                        cancel: null,
                        code: resp.response && resp.response.status ? resp.response.status : null,
                    };

                    commit('setSearchReport', data);

                    if (Object.keys(data).length===0) {
                        result.status = 'empty';
                    }

                    commit('setStatusSearchReport', result);
                })
                .catch((resp) => {
                    // console.log(resp)
                    const result = {
                        status: 'failed',
                        cancel: null,
                        code: resp.response && resp.response.status ? resp.response.status : null,
                    };

                    if (axiosOrigin.isCancel(resp)) {
                        result.status = 'loading';
                        result.cancel = cancelSearchReport;
                    }

                    commit('setStatusSearchReport', result);
                    if (!axiosOrigin.isCancel(resp)) {
                        dispatch('errors/handleApiResponse', [result.code, (resp.response.data && resp.response.data.message ? resp.response.data.message : null)], {root: true});
                    }
                });
        },
    },
};

export default news;
