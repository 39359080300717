<template lang="pug">
    .block
        aside.graph-layouts__sidebar
            .graph-navigation
                .graph-navigation__top
                    button.btn-nav.btn-nav--gatra(@click="openOpsi('gatra')" v-closable-menu-list-gatra="{exclude: ['menuListGatra'], handler: 'closeMenuListGatra'}")
                    //- button.btn-nav.btn-nav--isu(@click="openOpsi('isu')" v-closable-menu-list-issue="{exclude: ['menuListIssue'], handler: 'closeMenuListIssue'}")
                    button.btn-nav.btn-nav--ancaman(@click="openOpsi('ancaman')" v-closable-menu-list-ancaman="{exclude: ['menuListAncaman'], handler: 'closeMenuListAncaman'}")
                    button.btn-nav.btn-nav--indikator(@click="openOpsi('indikator')" v-closable-menu-list-indikator="{exclude: ['menuListIndikator'], handler: 'closeMenuListIndikator'}")
                .graph-navigation__bottom
                    router-link.btn-nav.btn-nav--bdi(to='/bdi/organisasi')
                    router-link.btn-nav.btn-nav--online(to='/manajemen/isu')
                    //- router-link.btn-nav.btn-nav--online(to='/report')
            .floating-more(@click="openOpsi('more')" v-closable-menu-sidebar-general-graph="{exclude: ['menuSidebarGeneralActiveGraph'], handler: 'closeMenuGeneralSidebar'}")
                b-button(icon_only="more-horizontal" kind="tertiary")

        .card-menu-popup.card-menu-popup--gatra(:class="{'card-menu-popup--show' : openOpsiGatra}" ref="menuListGatra")
            button.btn-item(v-if="status_list_gatra.status === 'loading'")
                b-loading(kind="circular" text="" height="0")

            template(v-else)

                template(v-if="opsiGatra.length > 0")
                    //- button.btn-item(v-for="(item, index) in opsiGatra" @click="chooseGatra(index)" :key="index" :class="{'btn-item--selected' : item.selected}")
                    button.btn-item(v-for="(item, index) in opsiGatra" @click="selectedGatra !== item.id ? chooseGatra(index) : null" :key="index" :class="{'btn-item--selected' : (selectedGatra === item.id)}")
                        .mr-2
                            .icon-bullet
                        span {{ item.label }}

                .menu-popup__blank(v-else)
                    .block
                        b-icon.mb-2(name="info" size="2x")
                        span Gatra tidak ditemukan.

        //- .card-menu-popup.card-menu-popup--isu(:class="{'card-menu-popup--show' : openOpsiIsu}" ref="menuListIssue")
            .menu-popup__blank(v-if="selectedGatra === ''")
                .block
                    b-icon.mb-2(name="info" size="2x")
                    span Pilih Gatra untuk menampilkan isu.

            template(v-else)
                template(v-if="opsiIssue.length > 0")
                    button.btn-item(v-for="(item, index) in opsiIssue" @click="chooseIssue(index)" :class="{'btn-item--selected' : (selectedIssue === item.title)}")
                        .mr-2
                            .icon-bullet
                        span(v-tooltip="item.title.length > 22 ? item.title : ''") {{ item.title.length > 22 ? item.title.substr(0, 22) +'...' : item.title }}

                template(v-if="opsiIssue.length > 0 || status_list.status === 'loading'")
                    button.btn-item(v-if="status_list.status === 'loading'")
                        b-loading(kind="circular" text="" height="0")

                    template(v-else)
                        .text-center.pt-2.pb-2(v-if="list.current_page && list.total_page && list.current_page < list.total_page")
                            b-button(text="Lainnya..." kind="tertiary" size="small" @click.native="loadMoreIssue()")

                .menu-popup__blank(v-else)
                    .block
                        b-icon(name="info")
                        span Isu tidak ditemukan.
        
        .card-menu-popup.card-menu-popup--ancaman(:class="{'card-menu-popup--show' : openOpsiAncaman}" ref="menuListAncaman")
            .menu-popup__blank(v-if="selectedGatra === ''")
                .block
                    b-icon.mb-2(name="info" size="2x")
                    span Pilih Gatra untuk menampilkan ancaman.

            template(v-else)
                template(v-if="opsiAncaman.length > 0")
                    //- button.btn-item(v-for="(item, index) in opsiAncaman" @click="chooseAncaman(index)" :class="{'btn-item--selected' : (selectedAncaman === item.id)}")
                    button.btn-item(v-for="(item, index) in opsiAncaman" @click="chooseAncaman(index)" :class="{'btn-item--selected' : (selectedAncaman.includes(item.id))}")
                        .mr-2
                            .icon-bullet
                        span(v-tooltip="item.label.length > 22 ? item.label : ''") {{ item.label.length > 22 ? item.label.substr(0, 22) +'...' : item.label }}

                template(v-if="opsiAncaman.length > 0 || status_list_ancaman.status === 'loading'")
                    button.btn-item(v-if="status_list_ancaman.status === 'loading'")
                        b-loading(kind="circular" text="" height="0")

                    //- template(v-else)
                        .text-center.pt-2.pb-2(v-if="list.current_page && list.total_page && list.current_page < list.total_page")
                            b-button(text="Lainnya..." kind="tertiary" size="small" @click.native="loadMoreAncaman()")

                .menu-popup__blank(v-else)
                    .block
                        b-icon(name="info")
                        span Ancaman tidak ditemukan.

        .card-menu-popup.card-menu-popup--indikator(:class="{'card-menu-popup--show' : openOpsiIndikator}" ref="menuListIndikator")
            .menu-popup__blank(v-if="selectedAncaman.length === 0")
                .block
                    b-icon.mb-2(name="info" size="2x")
                    span Pilih ancaman untuk menampilkan indikator.

            template(v-else)
                template(v-if="opsiIndikator.length > 0")
                    //- button.btn-item(v-for="(item, index) in opsiIndikator" @click="chooseIndikator(index)" :class="{'btn-item--selected' : (selectedIndikator === item.id)}")
                    button.btn-item(v-for="(item, index) in opsiIndikator" @click="chooseIndikator(index)" :class="{'btn-item--selected' : (selectedIndikator.includes(item.id))}")
                        .mr-2
                            .icon-bullet
                        span(v-tooltip="item.label.length > 22 ? item.label : ''") {{ item.label.length > 22 ? item.label.substr(0, 22) +'...' : item.label }}

                template(v-if="opsiIndikator.length > 0 || status_list_indikator.status === 'loading'")
                    button.btn-item(v-if="status_list_indikator.status === 'loading'")
                        b-loading(kind="circular" text="" height="0")

                    //- template(v-else)
                        .text-center.pt-2.pb-2(v-if="list.current_page && list.total_page && list.current_page < list.total_page")
                            b-button(text="Lainnya..." kind="tertiary" size="small" @click.native="loadMoreIndikator()")

                .menu-popup__blank(v-else)
                    .block
                        b-icon(name="info")
                        span Indikator tidak ditemukan.

            //- button.btn-item(v-for="item in OpsiIsu" @click="item.selected = !item.selected"  :class="{'btn-item--selected' : item.selected}")
                .mr-2
                    .icon-bullet
                span {{ item.name}}

        .card-menu-popup.card-menu-popup--more(:class="{'card-menu-popup--show' : openMore}" ref="menuSidebarGeneralActiveGraph")
            MenuSidebarGeneral

</template>

<script>
import {mapState} from 'vuex';
// import Vue from 'vue';
import MenuSidebarGeneral from '@/components/MenuSidebarGeneral';
//- let handleOutsideClickListIssue;
let handleOutsideClick, handleOutsideClickListGatra, handleOutsideClickListAncaman, handleOutsideClickListIndikator;

export default {
    name: 'GrahpSidebar',
    components: {
        MenuSidebarGeneral,
    },
    directives: {
        'closable-menu-sidebar-general-graph': {
            bind (el, binding, vnode) {
                handleOutsideClick = (e) => {
                    e.stopPropagation();
                    const { handler, exclude } = binding.value;
                    let clickedOnExcludedEl = false;

                    exclude.forEach(refName => {
                        if (!clickedOnExcludedEl) {
                            const excludedEl = vnode.context.$refs[refName];
                            if (excludedEl)
                                clickedOnExcludedEl = excludedEl.contains(e.target);
                        }
                    })

                    if (!el.contains(e.target) && !clickedOnExcludedEl) {
                        vnode.context[handler]();
                    }
                }
                document.addEventListener('click', handleOutsideClick);
                document.addEventListener('touchstart', handleOutsideClick);
            },
            unbind () {
                document.removeEventListener('click', handleOutsideClick);
                document.removeEventListener('touchstart', handleOutsideClick);
            }
        },
        'closable-menu-list-gatra': {
            bind (el, binding, vnode) {
                handleOutsideClickListGatra = (e) => {
                    e.stopPropagation();
                    const { handler, exclude } = binding.value;
                    let clickedOnExcludedEl = false;

                    exclude.forEach(refName => {
                        if (!clickedOnExcludedEl) {
                            const excludedEl = vnode.context.$refs[refName];
                            if (excludedEl)
                                clickedOnExcludedEl = excludedEl.contains(e.target);
                        }
                    })

                    if (!el.contains(e.target) && !clickedOnExcludedEl) {
                        vnode.context[handler]();
                    }
                }
                document.addEventListener('click', handleOutsideClickListGatra);
                document.addEventListener('touchstart', handleOutsideClickListGatra);
            },
            unbind () {
                document.removeEventListener('click', handleOutsideClickListGatra);
                document.removeEventListener('touchstart', handleOutsideClickListGatra);
            }
        },
        //- 'closable-menu-list-issue': {
        //-     bind (el, binding, vnode) {
        //-         handleOutsideClickListIssue = (e) => {
        //-             e.stopPropagation();
        //-             const { handler, exclude } = binding.value;
        //-             let clickedOnExcludedEl = false;

        //-             exclude.forEach(refName => {
        //-                 if (!clickedOnExcludedEl) {
        //-                     const excludedEl = vnode.context.$refs[refName];
        //-                     if (excludedEl)
        //-                         clickedOnExcludedEl = excludedEl.contains(e.target);
        //-                 }
        //-             })

        //-             if (!el.contains(e.target) && !clickedOnExcludedEl) {
        //-                 vnode.context[handler]();
        //-             }
        //-         }
        //-         document.addEventListener('click', handleOutsideClickListIssue);
        //-         document.addEventListener('touchstart', handleOutsideClickListIssue);
        //-     },
        //-     unbind () {
        //-         document.removeEventListener('click', handleOutsideClickListIssue);
        //-         document.removeEventListener('touchstart', handleOutsideClickListIssue);
        //-     }
        //- },
        'closable-menu-list-ancaman': {
            bind (el, binding, vnode) {
                handleOutsideClickListAncaman = (e) => {
                    e.stopPropagation();
                    const { handler, exclude } = binding.value;
                    let clickedOnExcludedEl = false;

                    exclude.forEach(refName => {
                        if (!clickedOnExcludedEl) {
                            const excludedEl = vnode.context.$refs[refName];
                            if (excludedEl)
                                clickedOnExcludedEl = excludedEl.contains(e.target);
                        }
                    })

                    if (!el.contains(e.target) && !clickedOnExcludedEl) {
                        vnode.context[handler]();
                    }
                }
                document.addEventListener('click', handleOutsideClickListAncaman);
                document.addEventListener('touchstart', handleOutsideClickListAncaman);
            },
            unbind () {
                document.removeEventListener('click', handleOutsideClickListAncaman);
                document.removeEventListener('touchstart', handleOutsideClickListAncaman);
            }
        },
        'closable-menu-list-indikator': {
            bind (el, binding, vnode) {
                handleOutsideClickListIndikator = (e) => {
                    e.stopPropagation();
                    const { handler, exclude } = binding.value;
                    let clickedOnExcludedEl = false;

                    exclude.forEach(refName => {
                        if (!clickedOnExcludedEl) {
                            const excludedEl = vnode.context.$refs[refName];
                            if (excludedEl)
                                clickedOnExcludedEl = excludedEl.contains(e.target);
                        }
                    })

                    if (!el.contains(e.target) && !clickedOnExcludedEl) {
                        vnode.context[handler]();
                    }
                }
                document.addEventListener('click', handleOutsideClickListIndikator);
                document.addEventListener('touchstart', handleOutsideClickListIndikator);
            },
            unbind () {
                document.removeEventListener('click', handleOutsideClickListIndikator);
                document.removeEventListener('touchstart', handleOutsideClickListIndikator);
            }
        },
    },
    data() {
        return {
            openOpsiGatra: false,
            openOpsiIsu: false,
            openOpsiAncaman: false,
            openOpsiIndikator: false,
            openMore: false,

            opsiGatra: [],
            selectedGatra: '',
            selectedGatraLabel: '',
            fromOpsiIsuSection: false,
            page_issue: 1,
            limit_issue: 10,
            opsiIssue: [],
            selectedIssue: '',
            
            fromOpsiAncamanSection: false,
            opsiAncaman: [],
            selectedAncaman: [],
            selectedAncamanLabel: [],
            
            fromOpsiIndikatorSection: false,
            opsiIndikator: [],
            selectedIndikator: [],
            selectedIndikatorLabel: [],
        };
    },
    computed: {
        ...mapState('graph', [
            'status_list_gatra',
            'list_gatra',

            'filterGatra',
            // 'filterIssue',
            // 'filterSource',
            // 'filterDateFrom',
            // 'filterDateTo',
            'filterAncaman',
            'filterIndikator',
        ]),
        //- ...mapState('issue', [
        //-     'status_list',
        //-     'list',
        //- ]),
        ...mapState('ancaman', {
            status_list_ancaman: state => state.status_list,
            list_ancaman: state => state.list,
        }),
        ...mapState('indikator', {
            status_list_indikator: state => state.status_list,
            list_indikator: state => state.list,
        }),
    },
    watch: {
        list_gatra(newVal) {
            let opsiGatra = [];

            if (newVal && newVal.length > 0) {
                opsiGatra = newVal;

                Object.keys(opsiGatra).map(function(key, index) {
                    opsiGatra[index]['selected'] = false;
                });

                if (this.filterGatra !== '') {
                    const filterGatra = this.filterGatra;
                    const check_opsi = opsiGatra.filter(function(item) {
                        return item.id === filterGatra;
                    });

                    if (check_opsi.length > 0) {
                        this.selectedGatraLabel = check_opsi[0].label;
                        this.setFilterLabel();
                    }
                }
            }

            this.opsiGatra = opsiGatra;
        },
        // opsiGatra() {},
        selectedGatra(newVal) {
            if (newVal !== '') {
                //- //- this.opsiIssue = [];
                //- this.opsiAncaman = [];
                //- this.opsiIndikator = [];
                //- //- this.selectedIssue = '';
                //- this.selectedAncaman = [];
                //- this.selectedIndikator = [];

                //- //- this.page_issue = 1;
                //- //- this.getListIssue();
                this.getListAncaman();

            }
            //-  else if (newVal === '') {} {
            //-     this.getSearchEvent();
            //- }
        },
        selectedAncaman(newVal) {
            if (newVal.length > 0) {
                //- this.opsiIndikator = [];
                //- this.selectedIndikator = [];

                this.getListIndikator();
            }
        },
        //- list(newVal) {
        //-     if (newVal && newVal.data && newVal.data.length > 0) {
        //-         for (let i = 0; i < newVal.data.length; i++) {
        //-             newVal.data[i].selected = false;
        //-             this.opsiIssue.push(newVal.data[i]);
        //-         }
        //-     }
        //- },
        list_ancaman(newVal) {
            //- if (newVal && newVal.data && newVal.data.length > 0) {
            if (newVal && newVal.length > 0) {

                let filterAncaman = [];
                if (this.filterAncaman !== '') {
                    filterAncaman = this.filterAncaman.split(',');
                }

                for (let i = 0; i < newVal.length; i++) {
                    newVal[i].selected = false;
                    this.opsiAncaman.push(newVal[i]);

                    if (filterAncaman.length > 0 && filterAncaman.includes(newVal[i].id)) {
                        this.selectedAncamanLabel.push(newVal[i].label);
                    }
                }

                if (filterAncaman.length > 0)
                    this.setFilterLabel();
            }
        },
        list_indikator(newVal) {
            if (newVal && newVal && newVal.variable && newVal.variable.length > 0) {

                let filterIndikator = [];
                if (this.filterIndikator !== '') {
                    filterIndikator = this.filterIndikator.split(',');
                }

                for (let i = 0; i < newVal.variable.length; i++) {
                    newVal.variable[i].selected = false;
                    this.opsiIndikator.push(newVal.variable[i]);

                    if (filterIndikator.length > 0 && filterIndikator.includes(newVal.variable[i].id)) {
                        this.selectedIndikatorLabel.push(newVal.variable[i].label);
                    }
                }

                if (filterIndikator.length > 0)
                    this.setFilterLabel();
            }
        },
    },
    methods: {
        openOpsi(typeOpsi) {
            if (typeOpsi === 'gatra') {
                this.openOpsiGatra = !this.openOpsiGatra;
                //- this.openOpsiIsu = false;
                this.openOpsiAncaman = false;
                this.openOpsiIndikator = false;
                this.openMore = false;

            //- } else if (typeOpsi === 'isu') {
            //-     this.openOpsiGatra = false;
            //-     this.openOpsiIsu = !this.openOpsiIsu;
            //-     this.openMore = false;

            } else if (typeOpsi === 'ancaman') {
                this.openOpsiGatra = false;
                //- this.openOpsiIsu = false;
                this.openOpsiAncaman = !this.openOpsiAncaman;
                this.openOpsiIndikator = false;
                this.openMore = false;

            } else if (typeOpsi === 'indikator') {
                this.openOpsiGatra = false;
                //- this.openOpsiIsu = false;
                this.openOpsiAncaman = false;
                this.openOpsiIndikator = !this.openOpsiIndikator;
                this.openMore = false;

            } else {
                this.openOpsiGatra = false;
                //- this.openOpsiIsu = false;
                this.openOpsiAncaman = false;
                this.openOpsiIndikator = false;
                this.openMore = !this.openMore;
            }

            Event.$emit('openIfoIan', false);
        },
        closeMenuGeneralSidebar() {
            this.openMore = false;
        },
        closeMenuListGatra() {
            this.openOpsiGatra = false;
        },
        //- closeMenuListIssue() {
        //-     if (this.fromOpsiIsuSection === false ) {
        //-         this.openOpsiIsu = false;
        //-     } else {
        //-         this.fromOpsiIsuSection = false;
        //-     }
        //- },
        closeMenuListAncaman() {
            if (this.fromOpsiAncamanSection === false ) {
                this.openOpsiAncaman = false;
            } else {
                this.fromOpsiAncamanSection = false;
            }
        },
        closeMenuListIndikator() {
            if (this.fromOpsiIndikatorSection === false ) {
                this.openOpsiIndikator = false;
            } else {
                this.fromOpsiIndikatorSection = false;
            }
        },
        chooseGatra(index) {
            this.selectedGatra = this.opsiGatra[index].id;
            this.selectedGatraLabel = this.opsiGatra[index].label;
            this.opsiAncaman = [];
            this.opsiIndikator = [];
            //- this.selectedIssue = '';
            this.selectedAncaman = [];
            this.selectedAncamanLabel = [];
            this.selectedIndikator = [];
            this.selectedIndikatorLabel = [];

            this.getSearchEvent();
        },
        //- chooseIssue(index) {
        //-     // this.selectedIssue = this.opsiIssue[index].id;
        //-     this.selectedIssue = this.opsiIssue[index].title;
        //-     this.getSearchEvent();
        //- },
        chooseAncaman(index) {
            //- this.selectedAncaman = this.opsiAncaman[index].id;

            if (this.selectedAncaman.includes(this.opsiAncaman[index].id)) {
                
                const indexSelected = this.selectedAncaman.indexOf(this.opsiAncaman[index].id);
                if (indexSelected > -1) {
                    this.selectedAncaman.splice(indexSelected, 1);
                    this.selectedAncamanLabel.splice(indexSelected, 1);
                }

            } else {
                this.selectedAncaman.push(this.opsiAncaman[index].id);
                this.selectedAncamanLabel.push(this.opsiAncaman[index].label);
            }

            this.opsiIndikator = [];
            this.selectedIndikator = [];
            this.selectedIndikatorLabel = [];

            this.getSearchEvent();
        },
        chooseIndikator(index) {
            //- this.selectedIndikator = this.opsiIndikator[index].id;

            if (this.selectedIndikator.includes(this.opsiIndikator[index].id)) {
                
                const indexSelected = this.selectedIndikator.indexOf(this.opsiIndikator[index].id);
                if (indexSelected > -1) {
                    this.selectedIndikator.splice(indexSelected, 1);
                    this.selectedIndikatorLabel.splice(indexSelected, 1);
                }

            } else {
                this.selectedIndikator.push(this.opsiIndikator[index].id);
                this.selectedIndikatorLabel.push(this.opsiIndikator[index].label);
            }

            this.getSearchEvent();
        },
        //- loadMoreIssue() {
        //-     this.page_issue += 1;
        //-     this.getListIssue();
        //-     this.fromOpsiIsuSection = true;
        //- },
        logout() {
            this.$store.dispatch('auth/logout').then((resp) => {
                if (resp.result && resp.result === 'success') {
                    // this.$router.push({ name: 'pageLogin' });
                    this.$router.push('/login');

                    // Event.$emit('showLoading', false);
                    // this.$swal.close();
                } else {
                    // alert('error');

                    this.$swal.fire({
                        title: 'Failed logout!',
                        icon: 'error',
                        text: (resp.data && resp.data.message ? resp.data.message : 'Something wrong.'),
                        // timer: 3000,
                    });
                }
            });
        },
        getListGatra() {
            // for dispatch get data
            this.$store.dispatch('graph/getListGatra');
        },
        getListIssue() {
            this.$store.dispatch('issue/getList', [this.limit_issue, this.page_issue, '', this.selectedGatra]);
        },
        getListAncaman() {
            this.$store.dispatch('ancaman/getList', this.selectedGatra);
        },
        async getListIndikator() {
            //- temporary
            //- this.$store.dispatch('indikator/getList', [this.selectedGatra, this.selectedAncaman[0]]);

            if (this.selectedAncaman.length > 0) {
                for (let i = 0; i < this.selectedAncaman.length; i++) {
                    //- this.selectedAncaman[i].selected = false;
                    await this.$store.dispatch('indikator/getList', [this.selectedGatra, this.selectedAncaman[i]]);
                }
            }
        },
        async getSearchEvent() {
            await this.$store.commit('graph/setFilterGatra', this.selectedGatra);
            // await this.$store.commit('graph/setFilterIssue', this.selectedIssue);
            await this.$store.commit('graph/setFilterAncaman', this.selectedAncaman.join(','));
            await this.$store.commit('graph/setFilterIndikator', this.selectedIndikator.join(','));

            //- async getSearchEvent({state, commit, dispatch}, [gatra=null, query=null, dateFrom=null, dateTo=null, source=null, ancaman=null, indikator=null]) {
            // this.$store.dispatch('graph/getSearchEvent', [this.selectedGatra, this.selectedIssue, null, null, null, this.selectedAncaman.join(','), this.selectedIndikator.join(',')]);
            this.$store.dispatch('graph/getSearchEvent', []);
            
            this.setFilterLabel();
        },
        setFilterLabel() {
            //- console.log(this.selectedGatraLabel, this.selectedAncamanLabel, this.selectedIndikatorLabel);
            Event.$emit('filterLabel', this.selectedGatraLabel, this.selectedAncamanLabel.join(', '), this.selectedIndikatorLabel.join(', '));
        },
        setFilterParams() {
            if (this.filterGatra !== '')
                this.selectedGatra = this.filterGatra;
            
            if (this.filterAncaman !== '')
                this.selectedAncaman = this.filterAncaman.split(',');

            if (this.filterIndikator !== '')
                this.selectedIndikator = this.filterIndikator.split(',');
        },
    },
    mounted() {
        // console.log('mounted on GrahpSidebar')
        this.setFilterParams();
        this.getListGatra();
    },
    // beforeRouteEnter (to, from, next) {
    //     console.log(to.name);
    //     console.log(ehe);
    //     next(vm => {
    //         console.log(to.name);


    //         vm.$store.commit('graph/setListGatra', []);

    //         // if (to.name === 'GeneralRolesIndex' && vm.$route.params.id === undefined)
    //         vm.getListGatra();
    //     })
    // },
    // beforeRouteLeave (to, from, next) {
    //     next();
    // },
};
</script>

<style lang="sass">
.menu-popup__blank
    @apply w-full flex items-center justify-center absolute p-12 text-center
    height: calc( 100% - 0.75rem )
</style>
