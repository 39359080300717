<template lang="pug">
    main.graph-layouts
        GraphAside
        GraphSearch
        GraphDynamics
        GraphInfo
        router-view

        //- POPUP DETAIL LAPORAN
        .modal.modal--filled(v-bind:class="{'modal--show' : openModalReport}" style="z-index: 9999" ref="bigSearchDetailReport" @click="clickInside()")
            .modal-container
                .modal-container__header
                    .flex-grow
                        h2 Laporan
                    b-button(icon_only="x" @click.native="openModalReport = !openModalReport" type="button")
                .modal-container__body
                    .relative.mt-10(v-if="status_detail_report === 'loading'")
                        b-loading(kind="circular")

                    template(v-else)
                        h2.subheading {{ title }}
                        template(v-if="detail_report && Object.keys(detail_report).length > 0")
                            //- p Lorem ipsum dolor sit amet, cons
                            //- p(v-for="(item,index) in detail_report" :key="index") {{ item.description_raw ? item.description_raw : item.description }}
                            //- p(v-for="(item,index) in detail_report" :key="index") {{ item.description }}
                            div(v-for="(item,index) in detail_report" :key="index")
                                hr
                                p.mt-5.mb-5(v-html="JSON.parse(item.description_raw)")

                        .block.text-center.mb-5(v-else)
                            hr.mb-10
                            span Detail laporan tidak ditemukan.

                    //- h2.subheading Laporan Malaysia Dibuka untuk Pengunjung Internasional Mulai 1 Januari
                    //- p Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

</template>

<script>
import GraphAside from '@/layouts/graph/Sidebar'
import GraphSearch from '@/layouts/graph/Search'
import GraphDynamics from '@/layouts/graph/Dynamics'
import GraphInfo from '@/layouts/graph/Info'
import { mapState } from 'vuex'

export default {
    name: 'GraphLayouts',
    components: {
        GraphAside,
        GraphSearch,
        GraphDynamics,
        GraphInfo,
    },
    data() {
        return {
            openModalReport: false,
            title: '',
            from: '', // big search result / dinamika ancaman
        };
    },
    computed: {
        ...mapState('news', [
            'status_detail_report',
            'detail_report',
        ]),
    },
    watch: {
        openModalReport(newVal) {
            if (newVal === false) {
                this.$store.commit('news/setDetailReport', []);
                this.title = '';
                // this.from = '';
                this.destroyEvent();
                this.registerEvent();
            }
        },
    },
    methods: {
        clickInside() {
            // console.log(this.from)
            if (this.from === 'big-search-result') {
                Event.$emit('closeReportDetailBigSearchResult');
            }
        },
        registerEvent() {
            Event.$on('modalReport', (title, from=null) => {
                // console.log(title, from)
                this.title = title;
                this.from = from;
                this.$store.dispatch('news/getDetailReport', title);
                this.openModalReport = true;
            });
        },
        destroyEvent() {
            Event.$off('modalReport');
        },
    },
    created() {
        this.registerEvent();
    },
};
</script>

<style lang="sass">

</style>

