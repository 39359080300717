import axios from "@/axios-main";
import axiosOrigin from "axios";

var cancelToken = axiosOrigin.CancelToken;
let cancelListRoleAccessControl;

const acl = {
  namespaced: true,
  state: {
    status_list_role_access_control: {
      status: false,
      cancel: null,
      code: null,
    },
    // status_detail: false,

    list_role_access_control: {},
    // detail: {},
  },
  mutations: {
    setStatusListRoleAccessControl: (state, status) => {
      state.status_list_role_access_control = status;
    },
    // setStatusDetail: (state, status) => {
    //   state.status_detail = status;
    // },

    setListRoleAccessControl: (state, data) => {
      state.list_role_access_control = data;
    },
    // setDetail: (state, data) => {
    //   state.detail = data;
    // },
  },
  getters: {},
  actions: {
    async getListRoleAccessControl({ state, commit, dispatch }, [limit=1000, page=1]) {

      if (state.status_list_role_access_control.cancel !== null && state.status_list_role_access_control.status === 'loading')
        await state.status_list_role_access_control.cancel('Operation canceled by the user.');

      let params = {
        limit: limit,
        page: page,
        // keyword: keyword,
      };
    
      await axios.get('bff/access-control/role', {
        params: params,
        cancelToken: new cancelToken(function executor(c) {
          cancelListRoleAccessControl = c;
          commit('setStatusListRoleAccessControl', {
            status: 'loading',
            cancel: c,
            code: null,
          });
        }),
      })
      .then((resp) => {
        // console.log(resp.data.data)
        let data = resp.data.data;
        let result = {
          status: true,
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        commit('setListRoleAccessControl', data);

        if (Object.keys(data).length===0)
          result.status = 'empty';

        commit('setStatusListRoleAccessControl', result);
      })
      .catch((resp) => {
        // console.log(resp)
        let result = {
          status: 'failed',
          cancel: null,
          code: resp.response && resp.response.status ? resp.response.status : null,
        };

        if (axiosOrigin.isCancel(resp)) {
          result.status = 'loading';
          result.cancel = cancelListRoleAccessControl;
        }

        commit('setStatusListRoleAccessControl', result);
        if (!axiosOrigin.isCancel(resp))
        dispatch('errors/handleApiResponse', [result.code, (resp.response && resp.response.data && resp.response.data.message ? resp.response.data.message : null)], { root: true });
      });
    },

    update({ commit, dispatch }, data) {      
    
      return axios.post('bff/access-control/store', data)
      .then((resp) => {
        console.log(resp.data)
        
        // if (resp.status === 200 && resp.data.message.toLowerCase() === 'success') {
        if (resp.status === 200) {
          return {result: 'success', data: resp.data};
        } else {
          return {result: 'failed', data: resp.data};
        }
      })
      .catch((resp) => {
        console.log(resp)
        dispatch('errors/handleApiResponse', (resp.response && resp.response.status ? resp.response.status : null), { root: true });
        return {result: 'failed', data: (resp.response && resp.response.data ? resp.response.data : null)};
      });
    },
  }
};

export default acl;
